import * as Sentry from "@sentry/react";
import React, { useContext } from "react";
import { Fragment, Suspense, useEffect, useMemo,useState } from "react";
import { useCookies } from "react-cookie";
import ReactGA from "react-ga4";
import { Toaster } from "react-hot-toast";
import {
  createRoutesFromChildren,
  matchRoutes,
  Route,
  Routes,
  useLocation,
  useNavigate,
  useNavigationType,
} from "react-router-dom";

import { MessageContainer,Overlay, Spinner } from "./components/components";
import Header from "./components/header/Header";
import SideMenu from "./components/sideMenu/SideMenu";
import { ThemeContext } from "./contexts/themeContext";
import data from "./data";
import routes from "./routes";

const { VITE_DASHBOARD_BASE_URL, MODE } = import.meta.env;

Sentry.init({
  dsn: "https://61dcd403bfafa3964021491ec3544ef5@o4506942856429568.ingest.us.sentry.io/4506955055366144",
  integrations: [
    Sentry.reactRouterV6BrowserTracingIntegration({
      useEffect: React.useEffect,
      useLocation,
      useNavigationType,
      createRoutesFromChildren,
      matchRoutes,
    }),
  ],
  beforeSend(event) {
    if (process.env.NODE_ENV === 'development') {
        return null; // Do not send the event to Sentry in PDF Editor Dev
    }
    return event; 
  },
  // Performance Monitoring
  tracesSampleRate: 1.0, //  Capture 100% of the transactions
  // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
  tracePropagationTargets: ["localhost", /^https:\/\/yourserver\.io\/api/],
  // Session Replay
  replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
  replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
  environment: MODE === "development" ? "development" : "production",
  enabled: MODE === "development" ? false : true,
});

const SentryRoutes = Sentry.withSentryReactRouterV6Routing(Routes);

function useQuery() {
  const { search } = useLocation();
  return useMemo(() => new URLSearchParams(search), [search]);
}

const App = () => {
  const navigate = useNavigate();
  const [userInfo, setUserInfo] = useState({ name: "", isLoadingApp: true });
  const [isLoading, setIsLoading] = useState(true);

  const { themeKey } = useContext(ThemeContext);

  const isNightMode = themeKey === "nightMode";
  const [cookies, setCookie, removeCookie] = useCookies(["ptpgToken", "tw"]);
  const query = useQuery();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location.pathname]);

  useEffect(() => {
    // Google Analytics
    if (process.env.NODE_ENV === "production") {
      ReactGA.initialize("G-DBW8QF3404");
    }
  }, []);

  useEffect(() => {
    // Google Analytics - track page view
    const currentPath = location.pathname + location.search;
    ReactGA.set({ page: currentPath });
    ReactGA.send({ hitType: "pageview", page: currentPath });
  }, [location]);

  useEffect(() => {
    const removeQueryParams = (params) => {
      const currentURL = new URL(window.location.href);
      let isURLUpdated = false;

      params.forEach((param) => {
        if (currentURL.searchParams.has(param)) {
          currentURL.searchParams.delete(param);
          isURLUpdated = true;
        }
      });

      if (isURLUpdated) {
        navigate(currentURL.pathname + currentURL.search, { replace: true });
      }
    };

    const getUserDetails = async () => {
      const query = new URLSearchParams(window.location.search);

      const centreKey = query.get("key");
      const timberWindowsUserKey = query.get("tw");

      const token = centreKey || cookies.ptpgToken;
      const twKey = timberWindowsUserKey || cookies.tw;
      // remove keys from url so that they don't get shared around
      removeQueryParams(["key", "tw"]);

      try {
        const response = await data.authenticateToken(token);

        let isAuthenticated = false;
        let isProblemAuthenticating = false;
        let name = "";
        let permissions = {};
        let userDetails = {};
        if (response && response.status === 200) {
          isAuthenticated = true;

          if (centreKey) {
            // expires in 2088 (auth server will kick user before that)lol
            setCookie("ptpgToken", centreKey, {
              path: "/",
              maxAge: 2147483647,
            });
          }

          if (twKey) {
            setCookie("tw", twKey, {
              path: "/",
              maxAge: 2147483647,
            });
          }

          userDetails = await data.getPersonDetails(token);

          if (userDetails) {
            name = userDetails.forename ? userDetails.forename : "";
            name += userDetails.surname ? " " + userDetails.surname : "";
          }
        } else {
          isProblemAuthenticating = true;
        }

        // add userDetails to sentry context
        await Sentry.setUser({
          ...userDetails,
          id: response.userId,
        });

        setUserInfo({
          token,
          twKey,
          isAuthenticated,
          permissions,
          isProblemAuthenticating,
          name,
          userDetails,
        });
      } catch (error) {
        setUserInfo({ isProblemAuthenticating: true });
      }

      setIsLoading(false);
    };

    getUserDetails();
  }, [cookies.ptpgToken, cookies.tw, navigate, setCookie]);

  const logout = async () => {
    // TODO: this logout function should actually call a logout endpoint on the auth database, as they are still logged in there, and can still go to the controlcentre
    removeCookie("ptpgToken");
    removeCookie("tw");
    setUserInfo({
      token: null,
      twKey: null,
      isAuthenticated: false,
      permissions: {},
      isProblemAuthenticating: false,
      name: "",
    });
    window.location = `${VITE_DASHBOARD_BASE_URL}/login/admin_logout.php`;
  };

  const toggleLoading = (isLoading) => {
    setIsLoading(isLoading);
  };

  if (isLoading) {
    return (
      <Fragment>
        <Overlay />
        <MessageContainer>
          <Spinner />
        </MessageContainer>
      </Fragment>
    );
  }

  if (userInfo.isProblemAuthenticating || !userInfo.isAuthenticated) {
    return <div>You are not authenticated to see this page</div>;
  }

  const pathsWithoutPadding = ["/serviceRequests/siteVisitMap", "/daleAnalytics/map"];
  const containerShouldHaveXPadding = !pathsWithoutPadding.some((path) => location.pathname.includes(path));

  const standaloneRoutes = ["/pdf/edit"];
  const isStandaloneRoute = standaloneRoutes.some((route) => window.location.pathname.includes(route));
  
  return (
    <>
      <Toaster
        position="bottom-right"
        toastOptions={{
          duration: 5000,
          position: "top-center",
        }}
      />
      <main
        className={`${isStandaloneRoute && "h-screen w-full overflow-hidden"} ${
          isNightMode ? "dark" : "light"
        } flex flex-col`}
        data-theme={isNightMode ? "dark" : "light"}
      >
        {!isStandaloneRoute && <Header userDetails={userInfo.userDetails} logout={logout} />}
        <div className="contain drawer h-full w-full lg:drawer-open">
          <input id="drawer-id" type="checkbox" className="drawer-toggle" />
          <div
            className={`drawer-content flex max-w-[2000px] flex-col ${containerShouldHaveXPadding ? "px-6" : ""}`}
            // @Hack - 66px is the height of the header
            style={{ height: "calc(100% - 66px)" }}
          >
            {/* Suspense because the routes are lazy loaded */}
            <Suspense fallback={<div />}>
              <SentryRoutes>
                {routes.map((route, index) => {
                  const Element = route.element;
                  return (
                    <Route
                      key={index}
                      path={route.path}
                      element={<Element userInfo={userInfo} token={userInfo.token} toggleLoading={toggleLoading} />}
                    />
                  );
                })}
              </SentryRoutes>
            </Suspense>
          </div>

          {!isStandaloneRoute && (
            <div className="drawer-side lg:bg-[#ffffe5]">
              <div className="bg-[#ffffe5]">
                <SideMenu />
              </div>
            </div>
          )}
        </div>
      </main>
    </>
  );
};

export default App;
