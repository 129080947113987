import styled from "styled-components";

// for Loading Overlay *********/
export const Overlay = styled.div`
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  position: absolute;
  background-color: #aaa;
  opacity: 0.4;
  z-index: 9999;
`;

export const Spinner = styled.div`
  border: 16px solid #f3f3f3;
  border-radius: 50%;
  border-top: 16px solid #91a5a5;
  width: 120px;
  height: 120px;
  -webkit-animation: spin 2s linear infinite;
  animation: spin 2s linear infinite;
  @-webkit-keyframes spin {
    0% {
      -webkit-transform: rotate(0deg);
    }
    100% {
      -webkit-transform: rotate(360deg);
    }
  }

  @keyframes spin {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
`;

export const MessageContainer = styled.div`
  position: absolute;
  width: 100%;
  z-index: 99999;
  margin-top: 20%;
  text-align: center;
  justify-content: center;
  display: flex;
`;
