import React, { useContext, useState, useEffect } from "react";
import { ThemeContext } from "../../contexts/themeContext";

//Here we’re storing the State of the checkbox (isToggled)
// using the React State Hook. When the onChange event
// is triggered we then update the State using setIsToggled.
const ToggleSwitch = ({ id, text, checked, onChange, border }) => {
  const onToggle = async () => {
    onChange();
  };
  return (
    <div className="w-full">
      <style>{`
      .toggle-wrapper {
        display: flex;
        align-items: center;
        width: 100%;
        ${border ?? ""}
        justify-content: space-between;
      }
       .toggle-wrapper  p { padding: 0 10px 0 0;}
        .toggle-switch {
          position: relative;
          display: inline-block;
          width: 50px;
          height: 25px;
        }
        .toggle-switch input[type="checkbox"] {
          display: none;
        }
        .toggle-switch .switch {
          position: absolute;
          cursor: pointer;
          background-color: rgb(195, 199, 198);
          border-radius: 25px;
          top: 0;
          right: 0;
          bottom: 0;
          left: 0;
          transition: background-color 0.2s ease;
        }
        .toggle-switch .switch::before {
          position: absolute;
          content: "";
          left: 2px;
          top: 2px;
          width: 21px;
          height: 21px;
          background-color: rgb(255, 254, 254);
          border-radius: 50%;
          transition: transform 0.3s ease;
        }
        .toggle-switch input[type="checkbox"]:checked + .switch::before {
          transform: translateX(25px);
          background-color: white;
        }
        .toggle-switch input[type="checkbox"]:checked + .switch {
          background-color: #859449;
        }
        `}</style>
      <span id={id} className="toggle-wrapper">
        <p>{text}</p>
        <label className="toggle-switch">
          <input type="checkbox" checked={checked} onChange={onToggle} />
          <span className="switch" />
        </label>
      </span>
    </div>
  );
};
export default ToggleSwitch;
