import React from "react";
import { NavLink, Link } from "react-router-dom";

const { VITE_DASHBOARD_BASE_URL } = import.meta.env;

const SideMenu = () => {
  return (
    <>
      <div className="bg-[#ffffe5]">
        <label htmlFor="drawer-id" className="drawer-overlay"></label>
        <ul className="menu h-full w-60 border-r-2 bg-[#ffffe5] dark:bg-base-200">
          <li>
            <a target="_blank" href={`${VITE_DASHBOARD_BASE_URL}/control/control_center.cgi`} rel="noreferrer">
              Dashboard
            </a>
          </li>
          <li>
            <a
              target="_blank"
              href={`${VITE_DASHBOARD_BASE_URL}/control/control_center_enquiries.cgi`}
              rel="noreferrer"
            >
              Enquiries
            </a>
          </li>
          <li>
            <a target="_blank" href={`${VITE_DASHBOARD_BASE_URL}/control/control_center_quotes.cgi`} rel="noreferrer">
              Quotes
            </a>
          </li>
          <li>
            <a
              target="_blank"
              href={`${VITE_DASHBOARD_BASE_URL}/control/control_center_orders_recent.cgi`}
              rel="noreferrer"
            >
              Orders
            </a>
          </li>
          <li>
            <a
              target="_blank"
              href={`${VITE_DASHBOARD_BASE_URL}/control/control_center_suppliers.cgi`}
              rel="noreferrer"
            >
              Suppliers
            </a>
          </li>
          <li>
            <a
              target="_blank"
              href={`${VITE_DASHBOARD_BASE_URL}/control/control_center_supplier_orders_funnel.cgi`}
              rel="noreferrer"
            >
              Supplier Orders
            </a>
          </li>
          <li>
            <a
              target="_blank"
              href={`${VITE_DASHBOARD_BASE_URL}/control/control_center_service_requests.cgi`}
              rel="noreferrer"
            >
              Service Requests
            </a>
          </li>
          {/* <li>
            <NavLink to="/serviceRequests" className="justify-between">
              Service Requests<span className="badge">New</span>
            </NavLink>
          </li> */}

          {false && (
            <li>
              <a
                target="_blank"
                href={`${VITE_DASHBOARD_BASE_URL}/control/control_center_service_requests.cgi`}
                rel="noreferrer"
              >
                Service Requests<span className="badge">New</span>
              </a>

              <ul>
                <li>
                  <NavLink to="/serviceRequests/siteVisitMap" end>
                    Site visit map
                  </NavLink>
                </li>
                <li>
                  <NavLink to="/serviceRequests/calendar" end>
                    Calendar
                  </NavLink>
                </li>
                <li>
                  <NavLink to="/serviceRequests/calendarEvents" end>
                    Add calendar event
                  </NavLink>
                </li>
              </ul>
            </li>
          )}
          <li>
            <Link to="/logistics">Logistics</Link>
            <ul>
              <li>
                <NavLink to="/logistics" end>
                  Logistics planner
                </NavLink>
              </li>
              <li>
                <NavLink to="/logistics/manageVehicles" end>
                  Manage vehicles
                </NavLink>
              </li>
              <li>
                <a href="https://pwg.maxoptra.com/" target="_blank" rel="noreferrer">
                  MaxOptra
                </a>
              </li>
            </ul>
          </li>
          <li>
            <NavLink to="/daleAnalytics/map" end>
              Dale map
            </NavLink>
          </li>
          <li>
            <a
              target="_blank"
              href={`${VITE_DASHBOARD_BASE_URL}/control/control_center_installers.cgi`}
              rel="noreferrer"
            >
              Installers
            </a>
          </li>
          <li>
            <a
              target="_blank"
              href={`${VITE_DASHBOARD_BASE_URL}/control/control_center_salespeople.cgi`}
              rel="noreferrer"
            >
              Salespeople
            </a>
          </li>
          <li>
            <a target="_blank" href={`${VITE_DASHBOARD_BASE_URL}/control/control_center_reports.cgi`} rel="noreferrer">
              Reports
            </a>
          </li>
          <li>
            <a target="_blank" href={`${VITE_DASHBOARD_BASE_URL}/control/control_center_staff.cgi`} rel="noreferrer">
              Staff
            </a>
          </li>
          <li>
            <a target="_blank" href={`${VITE_DASHBOARD_BASE_URL}/control/control_center_settings.cgi`} rel="noreferrer">
              Settings
            </a>
          </li>
        </ul>
      </div>
    </>
  );
};
export default SideMenu;
