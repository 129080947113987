import calendarIcon from "./calendar-icon-small.png";
import editIcon from "./edit.gif";
import emailIcon from "./email.gif";
import helpIcon from "./help_icon.gif";
import smileyFaceIcon from "./smile.gif";
import pwgHeaderLogo from "./pwg-tree-logo-90-82.png";
import pwgHeaderLogoDark from "./pwg-tree-logo.transparent.dark.png";
import viewIcon from "./view.gif";
import checkIcon from "./icon_check-green.svg";

export const icons = {
  calendarIcon,
  editIcon,
  emailIcon,
  helpIcon,
  smileyFaceIcon,
  pwgHeaderLogo,
  pwgHeaderLogoDark,
  viewIcon,
  checkIcon,
};
