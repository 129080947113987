// ******************************************************GENERAL*****************************************************************************

export const MAX_OPTRA_URL = "https://pwg.maxoptra.com";
export const PREFERRED_VEHICLE_ID_MENU_ORDER = [
  1, 2, 3, 4, 5, 6, 17, 21, 22, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 18, 19, 20,
];

export const TOAST_OPTIONS = {
  duration: 5000,
};

// ********************************************************DATE******************************************************************************

export const headerDateFormat = "dddd Do MMMM YYYY";

// *****************************************************CSS/THEMES***************************************************************************

export const THEME_STORAGE_NAME = "themePreference";

// ************** DAY-MODE *************

export const DAY_MODE_KEY = "dayMode";
export const DAY_MODE_STR = "Day Mode";
export const DAY_MODE = {
  font: "Verdana, Arial, Helvetica, sans-serif",
  colors: {
    font: "black",
    error: "#ff0000",
    primary: "#7a8e8d",
    backgroundColor: "white",
    heading: "#5D6797",
    pageHeading: "#c5651f",
    gray2: "#aeaeae",
    border: "#aeaeae",
    appContentBorder: "#bfbfbf",
    toolTipBackground: "#dde6e7",
    pwg: "#858b1f",
    dateDisplay: {
      backgroundColor: "#eff2de",
    },
    plannerTable: {
      driverStyle: {
        backgroundColor: "#F4EBE3",
        border: "#ccc",
      },
      driverHeaderStyle: {},
    },
    orderRefLink: {
      color: "#2A7DD1",
    },
    dashboardTitle: "#545b21",
    calendarEventPalette: ["#ffffff", "#fb7c3f", "#e0e4c2", "#bdd8c3", "e7a652"],
    loadingMessagesBackground: "#858b1f",
    form: {
      invalidInputHighlight: "#ff0000",
    },
    table: {
      tableHeader: "#e0e4c2",
      tableCellBorder: "#ccc",
      rowBackgroundColourOdd: "#F0F0F6",
      rowBackgroundColourEven: "#fff",
      rowBackgroundColourHighlight: "#dbe5c5",
    },
    styledTable: {
      color: "black",
      backgroundColor: "#f2f2f2",
    },
    styledTr: {
      color: "black",
    },
    styledTd: {},
    sideMenu: {
      color: "black",
      border: "#989b82",
      backgroundColor: "#fffecb",
      whiteDiv: {
        backgroundColor: "white",
        border: "black",
        color: "black",
      },
      linkStyle: {
        color: "#202020",
        border: "#989b82",
        backgroundSelected: "#c7cf93",
        backgroundUnselected: "#D5D9B9",
        isSubMenuItem: {
          backgroundSelected: "",
          backgroundUnselected: "",
        },
      },
      aLinkStyle: {
        color: "#202020",
        border: "#989b82",
        backgroundSelected: "#fff8cb",
        backgroundUnselected: "#e0e4c2",
      },
    },
    themeToggle: {
      border: "black",
      wrapper: {
        color: "black",
      },
    },
    icon: {
      graph: "black",
    },
    fieldContainer: {
      border: "black",
      backgroundColor: "white",
      color: "black",
    },
    fixedWidthLabel: {
      border: "black",
      backgroundColor: "white",
      color: "black",
    },
    fixedWidthInput: {
      border: "#dedede",
      backgroundColor: "white",
      color: "black",
    },
    simpleChart: {
      customToolTip: {
        backgroundColor: "white",
        color: "black",
      },
    },
    styledFixedWidthInput: { backgroundColor: "white" },
    header: {
      tabBackgroundLight: "#f2f6e4",
      tabBackground: "#d0d3b1",
      colorVisited: "#000000",
      activeTabBorder: "white",
      tabBorder: "#d0d3b1",
    },
    leaveCalendar: {
      userLeaveRequest: "#669933",
      otherPersonLeaveRequest: "#669933",
      rejectedLeaveRequest: "#ff4444",
      publicHoliday: "#787878",
      mandatoryHolidayDay: "#858b1f",
    },
  },
  zIndexes: {
    menu: 999,
  },
};

// ************** NIGHT-MODE *************

const NIGHT_MODE_COLORS = {
  backgroundColor: "#262622",
  welcomeHeaderColor: "#42491d",
  welcomeHeaderFontColor: "#ffff00",
  borderColor: "#444",
  fontColor: "#ffffff",
};
export const NIGHT_MODE_KEY = "nightMode";
export const NIGHT_MODE_STR = "Night Mode";
export const NIGHT_MODE = {
  font: "Verdana, Arial, Helvetica, sans-serif",
  colors: {
    font: NIGHT_MODE_COLORS.fontColor,
    error: "#ff0000",
    primary: "#7a8e8d",
    backgroundColor: NIGHT_MODE_COLORS.backgroundColor,
    heading: "#5D6797",
    pageHeading: "#ff8229",
    gray2: "#aeaeae",
    border: NIGHT_MODE_COLORS.borderColor,
    appContentBorder: NIGHT_MODE_COLORS.borderColor,
    toolTipBackground: "#3f575a",
    pwg: "#858b1f",
    dateDisplay: {
      backgroundColor: NIGHT_MODE_COLORS.welcomeHeaderColor,
      color: NIGHT_MODE_COLORS.welcomeHeaderFontColor,
    },
    plannerTable: {
      driverStyle: {
        backgroundColor: "#2E2E29",
        border: NIGHT_MODE_COLORS.borderColor,
      },
      driverHeaderStyle: {
        backgroundColor: NIGHT_MODE_COLORS.welcomeHeaderColor,
      },
    },
    orderRefLink: {
      color: "lightskyblue",
      visited: "#6d4b8d",
      hover: "#ff4040",
      active: "#ff4040",
    },
    dashboardTitle: "#d6dda1",
    calendarEventPalette: ["#ffffff", "#fb7c3f", "#e0e4c2", "#bdd8c3", "e7a652"],
    loadingMessagesBackground: "#858b1f",
    form: {
      invalidInputHighlight: "#ff0000",
    },
    table: {
      tableHeader: NIGHT_MODE_COLORS.welcomeHeaderColor,
      tableHeaderColor: "#ffffff",
      tableCellBorder: NIGHT_MODE_COLORS.borderColor,
      rowBackgroundColourOdd: "#4d4d80",
      rowBackgroundColourEven: "#666666",
      rowBackgroundColourHighlight: "#dbe5c5",
    },
    styledTable: {
      color: "black",
      backgroundColor: "lightgray",
    },
    styledTd: {
      background: "lightgray",
    },
    styledTr: {
      background: "lightgray",
      color: "black",
    },
    sideMenu: {
      backgroundColor: NIGHT_MODE_COLORS.backgroundColor,
      borderBottom: "",
      border: NIGHT_MODE_COLORS.borderColor,
      color: "darkgray",
      whiteDiv: {
        backgroundColor: NIGHT_MODE_COLORS.welcomeHeaderColor,
        border: NIGHT_MODE_COLORS.borderColor,
        color: NIGHT_MODE_COLORS.welcomeHeaderFontColor,
      },
      linkStyle: {
        color: NIGHT_MODE_COLORS.fontColor,
        border: NIGHT_MODE_COLORS.borderColor,
        backgroundSelected: "",
        backgroundUnselected: "",
        isSubMenuItem: {
          backgroundSelected: "#222226",
          backgroundUnselected: "#222226",
        },
      },
      aLinkStyle: {
        color: NIGHT_MODE_COLORS.fontColor,
        border: NIGHT_MODE_COLORS.borderColor,
        backgroundSelected: "",
        backgroundUnselected: "",
      },
    },
    themeToggle: {
      border: NIGHT_MODE_COLORS.borderColor,
      wrapper: {
        color: "lightgray",
      },
    },
    icon: {
      graph: "white",
    },
    fieldContainer: {
      border: NIGHT_MODE_COLORS.borderColor,
      backgroundColor: "none",
      color: NIGHT_MODE_COLORS.fontColor,
    },
    fixedWidthLabel: {
      border: NIGHT_MODE_COLORS.borderColor,
      backgroundColor: "none",
      color: NIGHT_MODE_COLORS.fontColor,
    },
    fixedWidthInput: {
      border: NIGHT_MODE_COLORS.borderColor,
      background: "lightgray",
      color: NIGHT_MODE_COLORS.fontColor,
    },
    styledFixedWidthInput: {
      border: NIGHT_MODE_COLORS.borderColor,
      background: "lightgray",
      color: NIGHT_MODE_COLORS.fontColor,
    },
    simpleChart: {
      customToolTip: {
        backgroundColor: NIGHT_MODE_COLORS.backgroundColor,
        color: "white",
      },
    },
    header: {
      tabBackgroundLight: "#73862d",
      tabBackgroundDark: "#858b1f",
      colorVisited: "lightgray",
      tabBackground: "#d0d3b1",
      color: "#FFFFFF",
      activeTabBorder: NIGHT_MODE_COLORS.borderColor,
      tabBorder: NIGHT_MODE_COLORS.borderColor,
    },
    leaveCalendar: {
      userLeaveRequest: "#669933",
      otherPersonLeaveRequest: "#669933",
      rejectedLeaveRequest: "#ff4444",
      publicHoliday: "#787878",
      mandatoryHolidayDay: "#858b1f",
    },
  },
  zIndexes: {
    menu: 999,
  },
};

// ************** THEME-SETTINGS *************

export const THEMES = { dayMode: DAY_MODE, nightMode: NIGHT_MODE };

export const THEME_LABELS = {
  dayMode: DAY_MODE_STR,
  nightMode: NIGHT_MODE_STR,
};

export const DEFAULT_THEME = {
  theme: DAY_MODE,
  key: DAY_MODE_KEY,
  label: DAY_MODE_STR,
};
