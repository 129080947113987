import React, { useContext } from "react";
import { NIGHT_MODE_STR } from "../../constants";
import { ThemeContext } from "../../contexts/themeContext";
import ToggleSwitch from "../toggle";

const Index = ({}) => {
  const { theme, setTheme, isDefaultTheme } = useContext(ThemeContext);

  if (!theme || Object.keys(theme).length < 1) {
    console.error(`No theme detected. Found ${JSON.stringify(theme)}`);
    return <></>;
  }

  const handleThemeToggle = () => {
    setTheme();
  };
  return (
    <ToggleSwitch
      id={"theme-toggle"}
      text={`Toggle ${NIGHT_MODE_STR}`}
      checked={!isDefaultTheme()}
      onChange={handleThemeToggle}
    />
  );
};
export default Index;
